@import "../../utils/includes.scss";

//
// Fonts
//

$fontFamily: "Roboto", sans-serif;
$boldFontFamily: "Montserrat", sans-serif;
$boldFontWeight: 700;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

//
// Colors
//

$textColor: #222222;
$linkColor: #222222;
$labelColor: #999999;
$formColor: #222222;
$utilColor: #222222;
$priceColor: #222222;
$lightColor: #999999;
$btnColorStep: #ffffff;
$titleColor: #222222;
$titleSecondaryColor: #ffffff;
$subtitleColor: #222222;
$subtitleSecondaryColor: #ffffff;
$successColor: #222222;
$warningColor: #666666;
$errorColor: #ff3526;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;


$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #16181e;
$calculatorTabsTextColor: #dedede;
$calculatorCloseTabs: #505050;
$calculatorCloseTabsColor: #e3e3e3;
$calculatorMainColor: #ffffff;
$calculatorButton: #f0710a;

$garageBackground: #ffffff;
$garageTextColor: #303030;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin: #AEAFAF;
$colorTopbarLogin: #222222;

$progressBarTitleBG: #00426b;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #00426b;
$progressBarCloseButtonBG: #005e99 ;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #666666;
$paymentCalculatorClosedColor: #ffffff;
$paymentCalculatorPanelBG: #000000;
$paymentCalculatorTermWrapperBG: #333333;
$paymentCalculatorTermWrapperColor: #999999;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #222222;
$paymentCalculatorTermColor: #aaaaaa;

$fontSize-1: 50px;
$fontSize-2: 40px;
$fontSize-3: 30px;
$fontSize-4: 16px;
$fontSizeSmall:14px;
$fontSizeFinePrint: 10px;

$fontSizeLineHeight-1: 60px;
$fontSizeLineHeight-2: 50px;
$fontSizeLineHeight-3: 40px;
$fontSizeLineHeight-4: 20px;
/**=======================
    Button
=========================*/
$bgBtnAlpha: #666666;
$colorBtnAlpha: #FFFFFF;
$btnColorAlpha: #FFFFFF;
$colorBtnOutlineAlpha: #333333;
$paddingButtonPrimary: 12px 25px;
$btnFontSize:16px;
$btnLineHeight:16px;

$bgBtnBeta: #ffffff;
$btnColorBeta: #222222;
$colorBtnBeta: #222222;
$colorBtnOutlineBeta: #222222;
$paddingButtonSecondary: 10px 15px;

$borderRadiusButton: 5px;

// ========
// Styles
// ========
@include breakpoint(mobile){
    .widget-sr{
        &.makes__chrysler,
        &.makes__jeep,
        &.makes__dodge,
        &.makes__ram{
            .LoginBanner .LoginBannerWrapper{
                height: 34px;
                max-height: 34px;
                .login-content{
                    display: flex;
                    #button-login.btn-login{
                        margin-left: 0;
                    }
                    #button-register.btn-register{
                        margin-left: 10px;
                    }
                }
            }
            .vehicle-tile-rebate-single{
                background: $bgBtnAlpha !important;
                .is-price{
                    color: $colorBtnAlpha !important;
                }
                span{
                    color: $colorBtnAlpha !important;
                }
            }
        }
    }
}
.widget-sr{
    @include clearfix;
    width: 100%;
    &.makes__chrysler,
    &.makes__jeep,
    &.makes__dodge,
    &.makes__ram{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        .is-centered{
            text-align: center;
        }
        .sr-button-outline-2,
        .sr-button-2.vehicle-cta-btn,
        .btn-step.is-darker{
            border: 2px solid $btnColorBeta!important;
            color: $btnColorBeta;
            background: $bgBtnBeta !important;
            border-radius: 5px!important;
            text-transform: uppercase!important;
            padding: 10px 23px!important;
        }

        .sr-button-outline-2:hover,
        .sr-button-2.vehicle-cta-btn:hover,
        .sr-button-outline-2.is-hover,
        .btn-step.is-darker:hover{
            border: none;
            color: $bgBtnBeta;
            background: $btnColorBeta !important;
            border-radius: 5px;
            padding: 12px 25px;
            opacity: 1;
        }
        .sr-button-outline-1,
        .sr-button-outline-2,
        .sr-button,
        .sr-title-1,
        .sr-title-2{
            font-family: $boldFontFamily !important;
            font-weight: $boldFontWeight !important;
        }
        .sr-title-1,
        .sr-title-2{
            text-transform: none;
        }
        .sr-button-2{
            &.is-fluid{
                    box-shadow: none !important;
                    border: 2px solid $btnColorBeta!important;

            }
        }
        .sr-button-1,
        .sr-button-2{
            box-shadow: none;
        }
        .widget-vehicle-quote-btn-secondary{
            background: transparent !important;
            &:hover{
                background: #ffffff !important;
            }
        }
        .showroom-build-and-price .btn-step icon{
            display: none !important;
        }
        .widget-vehicle-quote-btn-secondary{
            border: 2px solid #ffffff !important;
        }
        .widget-vehicle-quote-btn-secondary:hover{
            border: 2px solid #ffffff !important;
            background-color: #ffffff !important;
            color: #222222;
            opacity: 1 !important;
        }
        .vehicle__name{
            font-family: $boldFontFamily;
            font-weight: $boldFontWeight;
        }
        .sr-modal--white-close{
            color: #666666;
        }

        // *******************************
        // Widget login
        // *******************************

        .widget-login .dropdown-content{
            .is-light{
                color: $textColor !important;
            }
        }
        .sr-progressBar-bg-title,
        .sr-progressBar-closeButton{
            background-color: #222222 !important;
            color: #FFFFFF;
        }
        .sr-progressBar-bg-title{
            text-transform: uppercase;
        }
        .sr-progressBar-bg-title.is-open{
            color: #222222 !important;
            background-color: #FFFFFF !important;
        }
        .TransactionSummaryUnit--single:first-child {
            background-color: #FFFFFF;
            border-bottom-color: #AEAFAF !important;
        }
        .TransactionSummaryUnit--single:nth-child(2) {
            background-color: #E0E0E0;
            border-bottom-color: #6E6E6E !important;
        }
        .TransactionSummaryUnit--single:nth-child(3) {
            background-color: #999999;
            border-bottom-color: #222222 !important;
        }
        .message-handler.warning {
            background-color: #666666;
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper {
            .TransactionSummaryUnit--single.is-validate{
                background-color: #999999 !important;
                border-bottom-color: #666666 !important;
                &:before{
                    border-left-color: #999999 !important;
                }
            }
        }
        .TransactionSummaryUnit--single {
            .validFlag:before{
            background-image: -webkit-linear-gradient(304deg,transparent 50%,#666666 55%) !important;
            }
        }
        .TransactionProgressBar--progression{
            background-color: #222222;
        }

        // *******************************
        // Section Shop Online
        // *******************************

        #catalog{
            background: #f2f2f2;
        }

        .section-catalog-filter{
            .catalog-filter__trade-in-wrapper .trade-in-info{
                font-family: $boldFontFamily !important;
                font-weight: $boldFontWeight !important;
                color: #222222;
                font-size: 16px;
            }
            .catalog-filter-type-name{
                font-family: $boldFontFamily !important;
                font-weight: $boldFontWeight !important;
            }
            input{
                color: #222222;
            }
        }
        .catalog-filter-type-all{
            color: #222222;
            font-size: 14px;
            font-family: $boldFontFamily !important;
            font-weight: $boldFontWeight !important;
        }
        .vehicle-description{
            .vehicle-name{
                font-size: 20px;
                line-height: 30px;
                font-family: $boldFontFamily;
                font-weight: $boldFontWeight;
                .vehicle-body{
                    font-size: 14px;
                    line-height: 20px;
                    font-family: $fontFamily;
                    font-weight: normal;
                }
            }
        }
        .vehicle-payment{
            .taxes-detail{
                font-size: $fontSizeFinePrint !important;
                line-height: 15px;
                font-style: normal !important;
                font-family: $fontFamily;
            }
        }
        .VehicleDisclaimersContent{
            span{
                font-family: $fontFamily;
                color: $textColor;
            }
        }
        .vehicle-payment-amount{
            sup{
                vertical-align: super !important;
                font-size: $fontSize-4;
                position: relative;
                top: 4px;
            }
        }
        .vehicle-cost{
            .vehicle-price-label,
            .vehicle-price{
                color: #999999 !important;
                &.is-bold{
                    color: $textColor !important;
                }
            }
        }
        .vehicle-payment{
            .vehicle-payment-rate,
            .vehicle-payment-cashdown,
            .vehicle-payment-cost-credit,

            .vehicle-payment-rate-note{
                color: #999999 !important;
            }
            .vehicle-payment-title{
                color: #222222 !important;
            }
            .vehicle-payment-rate{
                sup{
                    font-size: 14px !important;
                    padding-left: 3px;
                    vertical-align: super !important;
                    position: relative;
                    top: 4px;
                }
            }
        }
        .vehicle-item{
            .vehicle-best-rebate{
                span{
                    color: #999999;
                }
            }
            .vehicle-info{
                border-radius: 0;
                box-shadow: none;
                border: 1px solid #999999;
            }
            .vehicle-payment-cashdown,
            .vehicle-payment-cost-credit,
            .vehicle-price,
            .vehicle-cost div{
                font-size: 14px !important;
                line-height: 20px;
            }
            .vehicle-payment-rate{
                font-size: 30px;
                line-height: 40px;
            }
            .vehicle-price-total{
                border-top: 2px solid #999999 !important;
            }

        }
        // *******************************************************
        // Section Build and Buy
        // ********************************************************

        .section-showroom-header{
            &__model-listing-item{
                color: #999999;
            }
        }
        .section-showroom-header__progressBar-stage{
            color: #999999;
            border-left: 1px solid #999999;
            &.validated{
                .progressBar-stage__active-effect{
                    background-color: #ffffff;
                    fill: $priceColor;
                }
                .icon{
                    color: $utilColor;
                }
            }
            &.selected{
                .progressBar-stage__active-effect{
                    background-color: #AEAFAF !important;
                    fill: #AEAFAF !important;
                }
                .progressBar-stage__content{
                    color: #ffffff;
                }
            }
            .icon-showroom{
                color: #999999;
            }
        }
        .section-showroom-header__model-listing-item{
            .info-vehicle{
                color: #999999;
            }
        }
        .vehicle-rebate{
            color: #999999;
        }
        .selection-year-bloc{
            border: 1px solid #999999;
            border-radius: 0;
            box-shadow: none;
            &__payment{
                color: #999999;
                span{
                    font-size: $fontSizeSmall !important; ;
                }
                .is-bold{
                    font-family: $fontFamily !important;
                    font-weight: $boldFontWeight !important;
                    color: $textColor !important;
                }
            }
            &__paymentMethod--values{
                .terms-highlight__apr{
                    font-family: $fontFamily !important;
                    font-weight: normal !important;
                }
            }
            &__paymentMethod--title{
                span{
                    color: #999999 !important;
                }
            }
            &__disclaimer{
                background-color: #E0E0E0;
            }
        }
        .sr-calculator-tabs{
            background-color: #222222 !important;
            border-top: 1px solid #999999;
        }
        .style-trim__lists__item{
            &.selected{
              background-color: #666666 !important;
            }
        }
        .paymentCalculator{
            &__payment{
                .is-util{
                    color: #FFFFFF !important;
                }
            }
        }
        .vehicle-overview{
            &__vehicle-title{
                font-size: 30px;
                line-height: 40px;
                .is-bold{
                    font-family: $fontFamily !important;
                    font-weight: $boldFontWeight !important;
                    &.trim{
                        font-family: $boldFontFamily !important;
                    }
                }
                .vehicle-make{
                    font-size: 20px;
                    line-height: 35px;
                }
            }
            .tile-payment{
                .is-price{
                    color: #666666;
                }
            }
        }

        .financing-section{
            &.tile-payment-info{
                .tile-payment-amount{
                    span{
                        font-style: normal;
                        color: #222222;
                    }
                }
                .tile-payment-rate{
                    color: #666666;
                    sup{
                        top: 4px;
                    }
                }
                .tile-payment-cashdown{
                    color: #222222 !important;
                    &-wrapper{
                        font-size: 14px;
                    }
                    &-note{
                        font-size: 16px;
                    }
                }
                .tile-frequency{
                    color: #222222;
                }
            }
            .is-bold{
                font-family: $fontFamily !important;
                &.is-price{
                    font-family: $boldFontFamily !important;
                }
            }
        }
        .car-equipments_summary{
            .features-bloc{
                color: #222222 !important;
            }
            .features-bloc--content{
                font-size: 14px;
            }
            .sr-subtitle-2{
                font-size: 20px;
                line-height: 30px;
                font-family: $fontFamily !important;
                font-weight: $boldFontWeight;
            }
            .specs-bloc--content{
                span{
                 color: #999999;
                }
            }
        }
        .vehicle-overview{
            &__title{
                font-size: 30px;
                line-height: 40px;
            }
            &__summary{
                .financing-section{
                    .tile-payment-cashdown-note{
                        font-size: 14px;
                        line-height: 25px;
                        padding-left: 5px;
                    }
                    .tile-payment-cashdown-wrapper{
                        font-size: 30px;
                        line-height: 35px;
                    }
                }
            }
            &__optionalButton{
                margin-top: 0 !important;
            }
        }
        .vdp-wrapper-warranties{
            .VehicleDisclaimersContent{
                span{
                    color: #999999;
                }
            }
        }

        .Appointment__content{
            .appointment__steps--single.is-validated,
            .appointment__steps--single {
                icon,
                .doneLabel{
                    color: #222222;
                    icon{
                        color: #222222;
                    }
                }
                .timeSavedBlock{
                    border: 1px solid #222222;
                }
            }

        }
        input[type=radio]:checked+label:after{
            background: #222222 !important;
        }
        .checkout-payment-select,
        .checkout__section-delivery{
            input[type=radio]:checked+label{
                background: #FFFFFF;
            }

        }
        .checkout{
            &__payment-options-subtitle--taxes{
                font-style: normal !important;
            }
        }
        .section-checkout-header__progressBar-stage.validated {
            .picto-showroom:first-child{
                color: #222222 !important;
            }
        }
        .confirm_deposit{
            &--title{
            color: #222222 !important;
            }
            &--icon{
                border-color: #222222 !important;
                .icon-Crochet{
                    color: #222222 !important;
                }
            }
        }
        // **********************
        // Header B&B
        // **********************
        .sr-paymentCalculator-background{
            background-color: #222222 !important;
        }
        .paymentCalculator{
            &__interests,
            &__cashdown-amount{
                color: #E0E0E0;
            }
        }
        .calculatorColumn{
            .is-util{
                color: #ffffff;
            }
        }
        .sr-calculator-tabs{
            li.current{
             border-color: #ffffff !important;
            }
        }
        .sr-paymentCalculator-dropdown {
            .Select-control{
                background-color: #333333 !important;
            }
        }
        .sr-paymentCalculator__calculatorTerm--background{
            .price{
                color: #999999;
                font-size: 14px;
            }
            .price-highlight{
                color: #E0E0E0 !important;
                font-size: 20px;
            }
            .rate{
                color: #666666;
            }
            &.selected{
                .term{
                    background-color: #666666;
                    .content span{
                        font-size: 30px;
                    }
                }
                .price-highlight{
                    color: #666666 !important;
                }
            }
        }
        .calculator-tradeIn{
            .sr-link{
                color: #ffffff !important;
            }
        }
        .accessory_wrapper.single .accessory_wrapper--check .icon{
            color: #333333;
        }
        .bloc-options__single{
            background-color: #999999;
            &.selected{
                background-color: #222222;
                .is-price{
                    color: #ffffff !important;
                }
            }
            .is-price{
                color: #666666 !important;
            }
            &--footer{
                background-color: transparent;
            }
            &--body{
                .option-list{

                    font-family: $fontFamily;
                }
                .option{
                    font-family: $fontFamily;
                    .fuel-bold{
                        font-weight: $boldFontWeight;
                    }
                }
            }
        }
        .bloc-options__single--footer{
            &.selected {
                .option-choose-cta{
                    background-color: #666666;
                    color: #ffffff;
                    border-color: #666666;
                }
                .option-from{
                    font-style: normal;
                }
                .bloc-options__single--body{
                    margin: 4px;
                }
            }
        }
        .bloc-options__single--body{
            margin: 1px;
            .option{
                color: #999999;
            }
        }
        .bloc-options__single{
            border-radius: 0 !important;
            box-shadow: none !important;
        }
        .section-summary__content {
            .trim{
                font-family: $boldFontFamily !important;
                font-weight: $boldFontWeight;
                color: #222222;
            }
        }
        .TradeInTile{
            &--content-official{
                img{
                    margin: 0 auto 45px;
                    max-width: 50px;
                }
            }
            &--content-official-title{
                .sr-subtitle-2{
                    font-size: 20px;
                    line-height: 30px;
                }
            }
            &--content-official-subtitle{
                font-size: 13px;
                line-height: 20px;
            }
        }

        .listing-used-filters-bloc{
            .metadata-button.selected{
                border: 2px solid #222222 !important;
                color: #222222 !important;
                background: #fff;
            }
        }
        .TransactionAction--single.is-validate {
            button img{
                filter: none !important;
            }
        }
        .accessory_wrapper.single {
            .accessory_wrapper--check icon{
                color: #222222;
            }
        }
        .style-specs__lists__see-more{
            font-style: normal;
        }
        .method-button-payment-bar button{
            color: #222222;
        }
        .listing-used-filters{
            .listing-used-filters-bloc{
                div{
                    color: #222222 !important;
                }
            }
        }
        .listing-used,
        .listing-tile-note,
        .listing-tile-note .vehicle-note{
            background-color: transparent;
        }
        #catalog .vehicle-note__wrapper{
            background: #f2f2f2;
        }
        .vdp-used-banner{
            .widget-vehicle-quote-btn-secondary{
             border: 2px solid #222222 !important;
            }
            .widget-vehicle-quote-btn-secondary:hover{
             border: 2px solid #222222 !important;
                background: #222222 !important;
                color: #FFFFFF;
            }
        }
        .vehicleBanner-photos .vehicleBanner-photo-button p.photo-available.is-util,
        .vehicleBanner-personsViewed .amount.is-price{
            color: #FFFFFF;
        }
        .calculator-term.selected .taxesIncluded-section .is-util{
            color: #666666;
        }
        .showroom-vdp-used-features-vehicle-info{
            &-title{
                font-size: 14px;
            }
            &-title,
            &-fuel-info{
            color: #666666;
            }
        }
        .tradeInTiles--section{
            padding-top: 40px;
        }
        .listing-tile .vehicle-certification{
            width: 100%;
            .certification-badge{
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
            }
            .certification-badge-secondary{
                margin-top: 0;
                align-items: center;
                justify-content: space-around;
                .certification-badge-secondary{
                    margin: 0;
                }
                .carfax-icon{
                    margin: 0;
                }
            }
        }
        &.-hiddenBuyOnline {
            .showroom-vdp-used,
            .showroom-vdp-new{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        &.-hiddenCatalogBuyOnline {
            .showroom-build-and-price__content{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        .payment-calculator-wrapper{
            z-index: auto;
        }

        &.trade-is-on-top{
            .listing-used-car-search>div:last-child {
                display: flex;
                flex-direction: column;
                #instant-estimate-bar-wrapper{
                    order: 1;
                    margin-top: 0 !important;
                }
                .listing-tiles{
                    order: 2;
                }
                .listing-used-button-loading{
                    order: 3;
                }
            }
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@include breakpoint(mobile){
    .widget-sr{
        .LoginBanner{
            .btn-login .label {
                font-size: 10px;
            }
        }
    }
}

@include breakpoint(mobileTablet) {
    .widget-sr{
        .LoginBanner{
            padding: 0 !important;
            .TransactionSummary--wrapper--title{
                span{
                    font-size: 10px;
                }
            }
            .login-content{
                padding: 0;
            }
            .label,
            .btn-login .label{
                font-size: 10px;
            }
        }
    }
}

@include breakpoint(desktop){
    .widget-sr{
        &.makes__chrysler,
        &.makes__jeep,
        &.makes__dodge,
        &.makes__ram{
            .payment-calculator-wrapper{
                z-index: 9;
            }
        }
    }
}